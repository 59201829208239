import { createRouter, createWebHistory } from "vue-router";
import routes from "./routes";
const router = createRouter({
  routes,
  history: createWebHistory(),
  scrollBehavior() {
    return { top: 0 };
  },
});
// 添加全局前置守卫
router.beforeEach(async (to, from, next) => {
  // 检查用户是否已登录
  //判断缓存在不在
  var data = {
    session_key: localStorage.getItem("session_key") || "",
    token: localStorage.getItem("token") || "",
    session_verify: localStorage.getItem("session_verify") || "",
  };
  // 获取查询参数
  const merchant_code: any = to.query.m;
  if (merchant_code != undefined) {
    localStorage.setItem("merchant_code", merchant_code);
  }
  const invite: any = to.query.i;
  if (invite != undefined) {
    localStorage.setItem("invite", invite);
  }
  if (data.session_key == "" && to.path != "/login/index" && to.path != "/localMaterial/phone_upload") {
    next("/login/index");
  } else {
    next();
  }
});
export default router;
